exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/Archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-completing-signup-js": () => import("./../../../src/pages/CompletingSignup.js" /* webpackChunkName: "component---src-pages-completing-signup-js" */),
  "component---src-pages-home-page-1-js": () => import("./../../../src/pages/HomePage1.js" /* webpackChunkName: "component---src-pages-home-page-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-light-home-js": () => import("./../../../src/pages/LightHome.js" /* webpackChunkName: "component---src-pages-light-home-js" */),
  "component---src-pages-manual-pg-js": () => import("./../../../src/pages/ManualPg.js" /* webpackChunkName: "component---src-pages-manual-pg-js" */),
  "component---src-pages-passwordless-signup-js": () => import("./../../../src/pages/PasswordlessSignup.js" /* webpackChunkName: "component---src-pages-passwordless-signup-js" */),
  "component---src-pages-person-details-album-js": () => import("./../../../src/pages/PersonDetailsAlbum.js" /* webpackChunkName: "component---src-pages-person-details-album-js" */),
  "component---src-pages-person-details-ex-js": () => import("./../../../src/pages/PersonDetailsEx.js" /* webpackChunkName: "component---src-pages-person-details-ex-js" */),
  "component---src-pages-sliders-js": () => import("./../../../src/pages/Sliders.js" /* webpackChunkName: "component---src-pages-sliders-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/Stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-রক্তবন্যা-js": () => import("./../../../src/pages/রক্তবন্যা.js" /* webpackChunkName: "component---src-pages-রক্তবন্যা-js" */),
  "component---src-pages-রক্তবিন্দু-js": () => import("./../../../src/pages/রক্তবিন্দু.js" /* webpackChunkName: "component---src-pages-রক্তবিন্দু-js" */)
}

